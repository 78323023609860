.payment {
    background-image: url('/assets/img/payments/payment-sprite.png');
    background-repeat: no-repeat;
    display: block;
}

.payment-alipay {
    width: 88px;
    height: 30px;
    background-position: 0 0;
}

.payment-american-express {
    width: 30px;
    height: 30px;
    background-position: -88px 0;
}

.payment-diners {
    width: 37px;
    height: 30px;
    background-position: -118px 0;
}

.payment-discover {
    width: 41px;
    height: 30px;
    background-position: -155px 0;
}

.payment-jbc {
    width: 36px;
    height: 30px;
    background-position: -196px 0;
}

.payment-klarna {
    width: 40px;
    height: 30px;
    background-position: -232px 0;
}

.payment-mastercard {
    width: 36px;
    height: 30px;
    background-position: -272px 0;
}

.payment-paypal {
    width: 80px;
    height: 30px;
    background-position: -308px 0;
}

.payment-sofort {
    width: 76px;
    height: 30px;
    background-position: -388px 0;
}

.payment-unionpay {
    width: 42px;
    height: 30px;
    background-position: -464px 0;
}

.payment-visa {
    width: 42px;
    height: 30px;
    background-position: -506px 0;
}

.payment-webmoney {
    width: 93px;
    height: 30px;
    background-position: -548px 0;
}

.payment-yandexm {
    width: 63px;
    height: 30px;
    background-position: -641px 0;
}




.flags-sprite {
    display: inline-block;
    background: url('/assets/img/flag-sprite.png') no-repeat;
    overflow: hidden; text-indent: -9999px;
    text-align: left;
    width: 30px;
    height: 20px;
}
 
.flags-sprite.flag-albania { background-position: -5px -0px; }
.flags-sprite.flag-algeria { background-position: -40px -0px; }
.flags-sprite.flag-argentina { background-position: -75px -0px; }
.flags-sprite.flag-australia { background-position: -110px -0px; }
.flags-sprite.flag-austria { background-position: -145px -0px; }
.flags-sprite.flag-bangladesh { background-position: -180px -0px; }
.flags-sprite.flag-belarus { background-position: -215px -0px; }
.flags-sprite.flag-belgium { background-position: -250px -0px; }
.flags-sprite.flag-bhutan { background-position: -285px -0px; }
.flags-sprite.flag-bolivia { background-position: -320px -0px; }
.flags-sprite.flag-bosnia-and-herzegovina { background-position: -355px -0px; }
.flags-sprite.flag-brazil { background-position: -5px -25px; }
.flags-sprite.flag-brunei { background-position: -40px -25px; }
.flags-sprite.flag-bulgaria { background-position: -75px -25px; }
.flags-sprite.flag-cambodia { background-position: -110px -25px; }
.flags-sprite.flag-cameroon { background-position: -145px -25px; }
.flags-sprite.flag-canada { background-position: -180px -25px; }
.flags-sprite.flag-chile { background-position: -215px -25px; }
.flags-sprite.flag-china { background-position: -250px -25px; }
.flags-sprite.flag-colombia { background-position: -285px -25px; }
.flags-sprite.flag-costa-rica { background-position: -320px -25px; }
.flags-sprite.flag-cote-divoire { background-position: -355px -25px; }
.flags-sprite.flag-croatia { background-position: -5px -50px; }
.flags-sprite.flag-cyprus { background-position: -40px -50px; }
.flags-sprite.flag-czech-republic { background-position: -75px -50px; }
.flags-sprite.flag-denmark { background-position: -110px -50px; }
.flags-sprite.flag-ecuador { background-position: -145px -50px; }
.flags-sprite.flag-egypt { background-position: -180px -50px; }
.flags-sprite.flag-eritrea { background-position: -215px -50px; }
.flags-sprite.flag-estonia { background-position: -250px -50px; }
.flags-sprite.flag-fiji { background-position: -285px -50px; }
.flags-sprite.flag-finland { background-position: -320px -50px; }
.flags-sprite.flag-france { background-position: -355px -50px; }
.flags-sprite.flag-georgia { background-position: -5px -75px; }
.flags-sprite.flag-germany { background-position: -40px -75px; }
.flags-sprite.flag-ghana { background-position: -75px -75px; }
.flags-sprite.flag-greece { background-position: -110px -75px; }
.flags-sprite.flag-guatemala { background-position: -145px -75px; }
.flags-sprite.flag-guinea { background-position: -180px -75px; }
.flags-sprite.flag-hungary { background-position: -215px -75px; }
.flags-sprite.flag-iceland { background-position: -250px -75px; }
.flags-sprite.flag-india { background-position: -285px -75px; }
.flags-sprite.flag-indonesia { background-position: -320px -75px; }
.flags-sprite.flag-ireland { background-position: -355px -75px; }
.flags-sprite.flag-israel { background-position: -5px -100px; }
.flags-sprite.flag-italy { background-position: -40px -100px; }
.flags-sprite.flag-jamaica { background-position: -75px -100px; }
.flags-sprite.flag-japan { background-position: -110px -100px; }
.flags-sprite.flag-jordan { background-position: -145px -100px; }
.flags-sprite.flag-kazakhstan { background-position: -180px -100px; }
.flags-sprite.flag-kenya { background-position: -215px -100px; }
.flags-sprite.flag-korea-north { background-position: -250px -100px; }
.flags-sprite.flag-korea-south { background-position: -285px -100px; }
.flags-sprite.flag-kuwait { background-position: -320px -100px; }
.flags-sprite.flag-kyrgyzstan { background-position: -355px -100px; }
.flags-sprite.flag-laos { background-position: -5px -125px; }
.flags-sprite.flag-latvia { background-position: -40px -125px; }
.flags-sprite.flag-lithuania { background-position: -75px -125px; }
.flags-sprite.flag-luxembourg { background-position: -110px -125px; }
.flags-sprite.flag-malaysia { background-position: -145px -125px; }
.flags-sprite.flag-maldives { background-position: -180px -125px; }
.flags-sprite.flag-malta { background-position: -215px -125px; }
.flags-sprite.flag-mauritius { background-position: -250px -125px; }
.flags-sprite.flag-mexico { background-position: -285px -125px; }
.flags-sprite.flag-monaco { background-position: -320px -125px; }
.flags-sprite.flag-mongolia { background-position: -355px -125px; }
.flags-sprite.flag-morocco { background-position: -5px -150px; }
.flags-sprite.flag-nepal { background-position: -40px -150px; }
.flags-sprite.flag-netherlands { background-position: -75px -150px; }
.flags-sprite.flag-new-zealand { background-position: -110px -150px; }
.flags-sprite.flag-norway { background-position: -145px -150px; }
.flags-sprite.flag-pakistan { background-position: -180px -150px; }
.flags-sprite.flag-panama { background-position: -215px -150px; }
.flags-sprite.flag-peru { background-position: -250px -150px; }
.flags-sprite.flag-philippines { background-position: -285px -150px; }
.flags-sprite.flag-poland { background-position: -320px -150px; }
.flags-sprite.flag-portugal { background-position: -355px -150px; }
.flags-sprite.flag-quatar { background-position: -5px -175px; }
.flags-sprite.flag-romania { background-position: -40px -175px; }
.flags-sprite.flag-russian-federation { background-position: -75px -175px; }
.flags-sprite.flag-saudi-arabia { background-position: -110px -175px; }
.flags-sprite.flag-serbia { background-position: -145px -175px; }
.flags-sprite.flag-singapore { background-position: -180px -175px; }
.flags-sprite.flag-slovakia { background-position: -215px -175px; }
.flags-sprite.flag-slovenia { background-position: -250px -175px; }
.flags-sprite.flag-south-africa { background-position: -285px -175px; }
.flags-sprite.flag-spain { background-position: -320px -175px; }
.flags-sprite.flag-sri-lanka { background-position: -355px -175px; }
.flags-sprite.flag-sweden { background-position: -5px -200px; }
.flags-sprite.flag-switzerland { background-position: -40px -200px; }
.flags-sprite.flag-taiwan { background-position: -75px -200px; }
.flags-sprite.flag-tanzania { background-position: -110px -200px; }
.flags-sprite.flag-turkey { background-position: -145px -200px; }
.flags-sprite.flag-uganda { background-position: -180px -200px; }
.flags-sprite.flag-ukraine { background-position: -215px -200px; }
.flags-sprite.flag-united-kingdom { background-position: -250px -200px; }
.flags-sprite.flag-united-states-of-america { background-position: -285px -200px; }
.flags-sprite.flag-uruguay { background-position: -320px -200px; }
.flags-sprite.flag-uzbekistan { background-position: -355px -200px; }
.flags-sprite.flag-venezuela { background-position: -5px -225px; }
.flags-sprite.flag-vietnam { background-position: -40px -225px; }